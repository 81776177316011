import React, { useState, useEffect } from "react";
import {
  Box,
  Button,
  Typography,
  useMediaQuery,
  useTheme,
  Grid,
  Tab,
  Tabs,
  CircularProgress,
} from "@mui/material";
import { useQuery, useMutation, useLazyQuery } from "@apollo/client";
import PheonixPaper from "../Components/PheonixPaper";
import { VoucherFormData } from "../Interfaces/VoucherFormdataProps";
import {
  GET_VOUCHERS_LOANTYPE,
  GET_BORROWER_BY_ID,
  GET_DAILY_LOAN_HISTORY,
} from "../graphql/queries";
import PheonixTable from "../Components/PheonixTable";
import themestyle from "../theme";
import { TEXT_MESSAGES } from "../constant";
import { useNavigate, useLocation } from "react-router-dom";
import { GridColDef } from "@mui/x-data-grid";
import { EDIT_VOUCHER_STATUS } from "../graphql/mutation";
import Switch from "@mui/material/Switch";
import FormControlLabel from "@mui/material/FormControlLabel";
import backarrow from "../assets/backarrow.svg";
interface StatusSwitchProps {
  id: string;
  status: boolean;
}
const Loanledger: React.FC = () => {
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("md"));
  const isWideScreen = useMediaQuery("(min-width:600px)");
  const isMobileSmall = useMediaQuery(theme.breakpoints.down("sm"));
  const isTablet = useMediaQuery(theme.breakpoints.between("sm", "md"));
  const navigate = useNavigate();
  const [searchQuery, setSearchQuery] = useState<string>("");
  const [selectedTab, setSelectedTab] = useState<number>(() => {
    const storedTab = localStorage.getItem("tab");
    return storedTab ? parseInt(storedTab, 10) : 0;
  });
  const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
    setSelectedTab(newValue);
    localStorage.setItem("tab", newValue.toString());

    if (newValue === 0) {
      localStorage.setItem(
        "LoanpaginationModel",
        JSON.stringify({ pageSize: 5, page: 0 })
      );
      voucherRefetch();
      localStorage.removeItem("LoanDailyPaginationModel");
    } else if (newValue === 1) {
      localStorage.setItem(
        "LoanDailyPaginationModel",
        JSON.stringify({ pageSize: 5, page: 0 })
      );
      setSelectedLoanNo(null);
      localStorage.removeItem("LoanpaginationModel");
    }
  };

  useEffect(() => {
    const storedTab = localStorage.getItem("tab");
    if (storedTab) {
      setSelectedTab(parseInt(storedTab, 10));
    }
  }, []);
  const location = useLocation();
  useEffect(() => {
    if (location.state && location.state.tab !== undefined) {
      setSelectedTab(location.state.tab);
    }
  }, [location.state]);
  const [previousTab, setPreviousTab] = useState<number>(0);
  const [selectedLoanNo, setSelectedLoanNo] = useState<number | null>(null);
  const [tableData, setTableData] = useState<VoucherFormData[]>([]);
  const [tableCount, setTableCount] = useState<number>(0);
  const [totalPages, setTotalPages] = useState<number>(1);
  const [title, settitle] = useState("");
  const [dataLoading, setdataLoading] = useState(false);
  const [columns, setColumns] = useState<GridColDef[]>([]);
  const [paginationModel, setPaginationModel] = useState(() => {
    const selectedTabPaginationModel =
      selectedTab === 0
        ? localStorage.getItem("LoanpaginationModel")
        : localStorage.getItem("LoanDailyPaginationModel");

    return selectedTabPaginationModel
      ? JSON.parse(selectedTabPaginationModel)
      : { pageSize: 5, page: 0 };
  });

  useEffect(() => {
    const storageKey =
      selectedTab === 0 ? "LoanpaginationModel" : "LoanDailyPaginationModel";

    localStorage.setItem(storageKey, JSON.stringify(paginationModel));
    return () => {
      if (selectedTab === 0) {
        localStorage.removeItem("LoanpaginationModel");
      } else {
        localStorage.removeItem("LoanDailyPaginationModel");
      }
    };
  }, [paginationModel, selectedTab]);

  useEffect(() => {
    const storageKey =
      selectedTab === 0 ? "LoanpaginationModel" : "LoanDailyPaginationModel";

    const storedPagination = localStorage.getItem(storageKey);
    if (storedPagination) {
      setPaginationModel(JSON.parse(storedPagination));
    } else {
      setPaginationModel({ pageSize: 5, page: 0 });
    }
  }, [selectedTab]);

  const {
    loading: customerLoading,
    error: customerError,
    data: customerData,
    refetch: voucherRefetch,
  } = useQuery(GET_VOUCHERS_LOANTYPE, {
    variables: {
      page: paginationModel.page,
      perPage: paginationModel.pageSize,
      searchQuery: searchQuery || "",
      loan_type: selectedTab === 0 ? "Monthly" : "Daily",
    },
    onCompleted: (data) => {
      const { totalPages, totalCount, voucher } = data.getBorrowersbyLoanType;
      const startIndex = paginationModel.page * paginationModel.pageSize + 1;
      const vouchersWithSerialNo = voucher.map(
        (voucher: VoucherFormData, index: number) => ({
          ...voucher,
          serial_no: (startIndex + index).toString(),
        })
      );
      setTableData(vouchersWithSerialNo);
      setTableCount(totalCount);
      setTotalPages(totalPages);
    },
  });
  useEffect(() => {
    setdataLoading(true);
    if (!customerLoading && !customerError && customerData) {
      const { totalPages, totalCount, voucher } =
        customerData.getBorrowersbyLoanType;
      const startIndex = paginationModel.page * paginationModel.pageSize + 1;
      const vouchersWithSerialNo = voucher.map(
        (voucher: VoucherFormData, index: number) => ({
          ...voucher,
          serial_no: (startIndex + index).toString(),
        })
      );
      setTableData(vouchersWithSerialNo);
      setTableCount(totalCount);
      setTotalPages(totalPages);
      setdataLoading(false);
    }
  }, [
    customerData,
    customerLoading,
    customerError,
    paginationModel.page,
    paginationModel.pageSize,
    selectedTab,
  ]);

  useEffect(() => {
    voucherRefetch();
  }, [selectedTab]);
  const handlePaginationChange = (newModel: any) => {
    setPaginationModel((prevModel: any) => ({
      ...prevModel,
      ...newModel,
    }));
  };
  const handleSearchQueryChange = (newQuery: string) => {
    setSearchQuery(newQuery);
    if (newQuery.trim() === "") {
      voucherRefetch({ variables: { searchQuery: {} } });
    } else {
      voucherRefetch();
    }
  };
  const StatusSwitch: React.FC<StatusSwitchProps> = ({ id, status }) => {
    const [currentStatus, setCurrentStatus] = useState<boolean>(status);
    const [editVoucherMutation] = useMutation(EDIT_VOUCHER_STATUS);

    const handleChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
      const newStatus = event.target.checked;
      setCurrentStatus(newStatus);

      try {
        await editVoucherMutation({
          variables: { id, status: newStatus ? "true" : "false" },
        });
      } catch (error) {
        console.error("Failed to update status:", error);
        setCurrentStatus(!newStatus);
      }
    };

    return (
      <FormControlLabel
        control={<Switch checked={currentStatus} onChange={handleChange} />}
        label=""
      />
    );
  };

  const handleloannoClick = (loan_no: number) => {
    navigate(`/daily/${loan_no}`, { state: { loan_no } });
  };
  const handleMonthlyloannoClick = (loan_no: number) => {
    navigate(`/monthly/${loan_no}`, { state: { loan_no } });
  };

  useEffect(() => {
    if (selectedTab === 0) {
      setColumns([
        {
          field: "serial_no",
          headerName: "S.No",
          width: isMobileSmall ? 100 : 75,
        },
        { field: "date", headerName: "Date", flex: 1, minWidth: 150 },
        {
          field: "voucher_no",
          headerName: "Voucher No",
          flex: 1,
          minWidth: 150,
        },
        {
          field: "loan_no",
          headerName: "Loan Number",
          flex: 1,
          minWidth: isMobileSmall ? 170 : 150,
          renderCell: (params) => (
            <Typography
              variant="body2"
              color="primary"
              style={{
                cursor: "pointer",
                textAlign: "start",
                display: "flex",
                justifyContent: "start",
                alignItems: "center",
                height: "100%",
                textDecoration: "underline",
              }}
              onClick={() => handleMonthlyloannoClick(params.row.loan_no)}
            >
              {params.row.loan_no}
            </Typography>
          ),
        },
        { field: "borrower_name", headerName: "Name", flex: 1, minWidth: 250 },
        { field: "address1", headerName: "Address", flex: 1, minWidth: 250 },
        {
          field: "loan_amt",
          headerName: "Loan Principal Amount",
          flex: 2,
          minWidth: 200,
        },
        {
          field: "balance_amount",
          headerName: "Loan Outstanding Amount",
          flex: 2,
          minWidth: 200,
        },
        {
          field: "status",
          headerName: "Status",
          flex: 2,
          minWidth: 150,
          renderCell: (params) => (
            <StatusSwitch
              id={params.row.id}
              status={params.value === true || params.value === "true"}
            />
          ),
        },
      ]);
    } else {
      setColumns([
        {
          field: "serial_no",
          headerName: "S.No",
          width: isMobileSmall ? 100 : 75,
        },
        { field: "date", headerName: "Date", flex: 1, minWidth: 150 },
        {
          field: "voucher_no",
          headerName: "Voucher No",
          flex: 1,
          minWidth: 150,
        },
        {
          field: "loan_no",
          headerName: "Loan Number",
          flex: 1,
          minWidth: isMobileSmall ? 170 : 150,
          renderCell: (params) => (
            <Typography
              variant="body2"
              color="primary"
              style={{
                cursor: "pointer",
                textAlign: "start",
                display: "flex",
                justifyContent: "start",
                alignItems: "center",
                height: "100%",
                textDecoration: "underline",
              }}
              onClick={() => handleloannoClick(params.row.loan_no)}
            >
              {params.row.loan_no}
            </Typography>
          ),
        },
        { field: "borrower_name", headerName: "Name", flex: 1, minWidth: 250 },
        { field: "address1", headerName: "Address", flex: 1, minWidth: 250 },
        {
          field: "loan_amt",
          headerName: "Loan Principal Amount",
          flex: 2,
          minWidth: 200,
        },
        {
          field: "dailybalance_amount",
          headerName: "Loan Outstanding Amount",
          flex: 2,
          minWidth: 200,
        },
        {
          field: "status",
          headerName: "Status",
          flex: 2,
          minWidth: isMobileSmall ? 125 : 100,
          renderCell: (params) => (
            <StatusSwitch
              id={params.row.id}
              status={params.value === true || params.value === "true"}
            />
          ),
        },
      ]);
    }
  }, [selectedTab, selectedLoanNo]);

  const handleBack = () => {
    if (selectedTab === 1) {
      setSelectedLoanNo(null);
      setSelectedTab(previousTab);
      if (previousTab === 0) {
        voucherRefetch();
      } else if (previousTab === 1) {
        setPaginationModel({ pageSize: 5, page: 0 });
        voucherRefetch();
      }
    } else {
      navigate(-1);
    }
  };
  useEffect(() => {
    if (selectedTab === 0) {
      voucherRefetch();
    } else if (selectedTab === 1) {
      voucherRefetch();
    }
  }, [selectedTab, voucherRefetch]);

  return (
    <Box style={{ padding: "15px" }}>
      {isWideScreen ? (
        <PheonixPaper
          style={{
            borderRadius: "10px",
            height: "70px",
            marginBottom: "20px",
          }}
        >
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              textAlign: "center",
              padding: isMobileSmall ? "15px 0" : "15px 30px",
            }}
          >
            <Typography
              style={{
                color: themestyle.colors.black,
                fontWeight: themestyle.fontWeight.sm,
                fontSize: isMobileSmall
                  ? themestyle.fontSizes.sm
                  : themestyle.fontSizes.medium,
              }}
            >
              {TEXT_MESSAGES.LOAN_LEDGER}
            </Typography>
          </div>
        </PheonixPaper>
      ) : (
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            textAlign: "center",
            padding: isMobileSmall ? "15px 0" : "15px 30px",
          }}
        >
          <Typography
            style={{
              color: themestyle.colors.black,
              marginRight: "10px",
              fontWeight: themestyle.fontWeight.sm,
              fontSize: isMobileSmall
                ? themestyle.fontSizes.sm
                : themestyle.fontSizes.medium,
            }}
          >
            {TEXT_MESSAGES.LOAN_LEDGER}
          </Typography>
        </div>
      )}
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          backgroundColor: themestyle.colors.white,
          padding: isMobileSmall ? "0px 10px" : "20px 20px 20px 40px",
          maxHeight: isMobileSmall ? "65vh" : isTablet ? "550px" : "480px",
          overflow:'hidden',
        }}
      >
        <Tabs
          value={selectedTab}
          onChange={handleTabChange}
          aria-label="tabs"
          variant="scrollable"
          scrollButtons="auto"
          TabIndicatorProps={{
            style: {
              backgroundColor: "primary",
              height: "3px",
              textTransform: "none",
            },
          }}
        >
          <Tab
            label="Monthly Loan Ledger"
            sx={{
              color: "black",
              fontSize: "16px",
              fontWeight: selectedTab === 0 ? "bold" : "normal",
              textTransform: "none",
              "&.Mui-selected": {
                color: "black",
              },
            }}
          />
          <Tab
            label="Daily Loan Ledger"
            sx={{
              color: "black",
              fontSize: "16px",
              fontWeight: selectedTab === 1 ? "bold" : "normal",
              textTransform: "none",
              "&.Mui-selected": {
                color: "black",
              },
            }}
          />
        </Tabs>
        <Box sx={{marginTop:'-3%'}}>
        <PheonixTable
          columns={columns}
          rows={tableData}
          rowCount={tableCount}
          paginationMode="server"
          paginationModel={paginationModel}
          loading={dataLoading}
          onPaginationModelChange={handlePaginationChange}
          searchQuery={searchQuery}
          onSearchQueryChange={handleSearchQueryChange}
        />
        </Box>
      </Box>
    </Box>
  );
};
export default Loanledger;
